.App {
    text-align: center;
}

.info-header {
    display: flex;
    flex-direction: column;
    height: 65px;
    justify-content: center;
}

.title-page {
    font-size: 1rem !important;
    text-transform: uppercase;
    margin-bottom: 0 !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.ant-btn.fi {
    height: 26px;
    width: 34px;
    margin: 0 2px;
    padding: 0;
    border-radius: 0;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:last-child {
    display: none;
}

.ant-picker-time-panel {
    display: none;
}

.ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
}

.ant-picker-cell:not(.ant-picker-cell-disabled):not(
        .ant-picker-cell-in-view
    ):not(.ant-picker-cell-range):not(.ant-picker-cell-range-hover):not(
        .ant-picker-cell-range-start
    ):not(.ant-picker-cell-in-view) {
    visibility: hidden;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
}

.site-layout-background,
.ant-menu-dark {
    background: #1f242c !important;
}

.ant-layout-header .anticon {
    color: rgba(255, 255, 255, 0.65);
}

.site-layout-background .ant-typography {
    color: rgba(255, 255, 255, 0.65);
}

.ant-tag {
    max-width: 300px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
}

.ant-table tr {
    cursor: pointer;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-item {
    border-radius: 0 !important;
    width: 100%;
    margin: 0 !important;
}

.highcharts-credits {
    color: #fff !important;
    fill: #fff !important;
}

.highcharts-breadcrumbs-button text {
    color: #1f242c !important;
    fill: #1f242c !important;
}

.quantity {
    text-align: center;
    white-space: break-spaces;
}

.quantity .ant-statistic-content {
    display: flex;
    flex-direction: column;
}

.quantity .ant-statistic-content-value {
    display: block !important;
}

.quantity .ant-statistic-content-suffix {
    display: block;
    line-height: 15px;
}

.ant-table tr.not-allowed-row {
    cursor: not-allowed;
}

.reports-download-icon {
    color: rgb(19, 173, 59);
    margin-left: 10px;
    font-size: 20px;
    font-weight: bold;
}

.ant-table-tbody tr:hover td,
.ant-table-tbody tr:active td {
    background: color-mix(in srgb, var(--hover-color) 10%, white) !important;
}

.ant-tabs-content .ant-table-container {
    height: 275px;
}

.ant-tabs-content .ant-pagination {
    margin: 0 !important;
    padding-top: 30px;
}

.ant-empty .ant-empty-description {
    color: rgba(0, 0, 0, 0.25);
}

.btn-danger {
    background-color: #ee2323;
    color: #fff !important;
    border-color: #ee2323 !important;
}

.modal-markups {
    width: 80% !important;
    max-width: 800px;
}

.form-markup .ant-form-item-control-input-content > div {
    width: 100%;
}

.editable-row:hover .ant-table-cell-fix-left {
    background: #fafafa !important;
}

.text-center {
    text-align: center;
}

.small span {
    font-size: 16px;
}
.small .ant-statistic-content-suffix span {
    font-size: 12px !important;
}

@media (max-width: 1024px) {
    .title-page {
        font-size: 0.75rem !important;
    }

    .last-update {
        font-size: 0.65rem;
    }

    .filter-btn,
    .sider-btn {
        max-width: 66px;
        background: #1f242c !important;
        height: 62px;
        z-index: 99;
    }

    .filter-btn.no-collapsed {
        margin-right: 300px;
        position: absolute;
        right: 0;
    }

    .sider-btn.no-collapsed {
        margin-left: 195px;
        position: absolute;
        left: 0;
    }

    .filter-aside {
        position: fixed !important;
        height: 100vh;
        right: 0;
        z-index: 999;
    }

    .menu-aside {
        position: fixed !important;
        height: 100vh;
        left: 0;
        z-index: 999;
    }
    .ant-collapse.licenses .ant-collapse-content-box {
        padding: 0;
    }
}
